import { forwardRef, useEffect, useRef } from "react";
import { Formik, Form, FormikErrors } from "formik";
import CreditCardForm from "../CreditCardForm";
import * as Yup from "yup";
import { parse, isFuture, isValid, format } from "date-fns";
import CustomInputField from "./CustomInputFiled";
import { useGetAuthenticatedUser } from "@/api/user";
import { useFormStore } from "@/contexts/paymentFlowContext";
import { generateSHASignature } from "@/utils/payment";
import { useTranslation } from "react-i18next";
import FormikCustomSelectField from "@/components/ui/FormikCustomSelectField";
import DatePicker from "@/components/Inputs/DayDatePicker";
import { usePostDonation } from "@/api/donations";
import config from "@/config/config";
import { PhoneNumberField } from "./PhoneNumberField";
import { useNavigate } from "@tanstack/react-router";
import { useGetCitiesByCountryName } from "@/api/geography";
import { t } from "i18next";
import useCurrencyStore from "@/store/currencyStore";
import { luhnCheck } from "@/utils/creditCard";

export type EgyptianCity = {
  arabic: string;
  english: string;
};

type FormRenderSchema = {
  email?: boolean;
  fullName?: boolean;
  phoneNumber?: boolean;
  address?: boolean;
  city?: boolean;
  creditCardForm?: boolean;
  collection_date?: boolean;
};

type CheckoutFormProps = {
  schema: FormRenderSchema;
  selectedOption: string;
};

const getPhoneValidation = (selectedOption: string) => {
  if (selectedOption === "Credit Card") {
    return Yup.string()
      .matches(/\d{9,15}/, t("yup.phoneNumberInvalid"))
      .optional();
  } else {
    return Yup.string()
      .matches(/\d{9,15}/, t("yup.phoneNumberInvalid"))
      .required(t("yup.phoneNumberRequired"));
  }
};

type InnerFormProps = {
  schema: FormRenderSchema;
  selectedOption: string;
  values: any;
  errors: any;
  submitCount: number;
  setFieldValue: (
    field: string,
    value: any,
  ) => Promise<void | FormikErrors<any>>;
  paymentFormRef: React.RefObject<HTMLFormElement>;
};
const InnerForm = ({
  schema,
  selectedOption,
  values,
  errors,
  submitCount,
  setFieldValue,
  paymentFormRef,
}: Readonly<InnerFormProps>) => {
  const { i18n } = useTranslation();
  const { data: egyptianCities, isLoading: citiesLoading } =
    useGetCitiesByCountryName("Egypt", { lang: i18n.language });
  const { setFormError } = useFormStore();

  useEffect(() => {
    if (submitCount > 0) {
      const hasErrors = Object.keys(errors).length > 0;
      setFormError(hasErrors);
    }
  }, [errors, submitCount]);

  return (
    <Form className="!m-0 flex flex-col gap-4">
      {schema.fullName && (
        <CustomInputField
          label={t("donationFlow.name")}
          name="fullName"
          placeholder={t("donationFlow.fullname")}
        />
      )}
      {schema.email && (
        <CustomInputField
          label={t("donationFlow.email")}
          name="email"
          type="email"
          placeholder={t("donationFlow.emailenter")}
        />
      )}
      {schema.phoneNumber && (
        <PhoneNumberField
          label={
            selectedOption === "Credit Card"
              ? t("donationFlow.phoneOp")
              : t("donationFlow.phonemandatory")
          }
          name="phoneNumber"
          placeholder={t("donationFlow.enterPhone")}
          optional={selectedOption === "Credit Card"}
        />
      )}
      {schema.address && (
        <CustomInputField
          label={t("donationFlow.address")}
          name="address"
          placeholder={t("donationFlow.enterAddress")}
        />
      )}
      {schema.city && !citiesLoading && egyptianCities && (
        <FormikCustomSelectField
          label={t("donationFlow.city")}
          name="city"
          options={egyptianCities?.map((city) => ({
            label: city.name,
            value: city.name,
          }))}
          searchable={true}
          searchPlaceholder={t("donationFlow.enterCity")}
        />
      )}
      {schema.collection_date && (
        <div className="mb-6">
          <label
            htmlFor="collection_date"
            className="text-forground mb-2 block text-sm font-medium"
          >
            {t("donationFlow.collection")}
          </label>
          <DatePicker
            selected={values.collection_date}
            name="collection_date"
            onChange={setFieldValue}
            placeholderText={t("donationFlow.date")}
            error={
              !values.collection_date && submitCount > 0
                ? errors.collection_date
                : ""
            }
          />
        </div>
      )}
      <form
        ref={paymentFormRef}
        className="!m-0 flex flex-col gap-4"
        method="post"
        action={config.paymentGateAPS}
      >
        {schema.creditCardForm && <CreditCardForm values={values} />}
      </form>
    </Form>
  );
};

const CheckoutForm = forwardRef<any, CheckoutFormProps>(
  ({ schema, selectedOption }, ref) => {
    const { currency } = useCurrencyStore();

    const validationSchema = Yup.object().shape({
      email: Yup.string()
        .email(t("yup.emailInvalid"))
        .required(t("yup.emailRequired")),
      fullName: Yup.string()
        .matches(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/, t("yup.fullNameAlpha"))
        .min(2, t("yup.fullNameMin"))
        .max(50, t("yup.fullNameMax"))
        .required(t("yup.fullNameRequired")),
      phoneNumber: getPhoneValidation(selectedOption),
      address: schema.address
        ? Yup.string()
            .min(1, t("yup.addressRequired"))
            .required(t("yup.addressRequired"))
        : Yup.string().notRequired(),
      city: schema.city
        ? Yup.string()
            .min(1, t("yup.cityRequired"))
            .required(t("yup.cityRequired"))
        : Yup.string().notRequired(),
      card_number: schema.creditCardForm
        ? Yup.string()
            .required(t("yup.cardNumberRequired"))
            .matches(/^(\d{4} ?){4}$/, t("yup.cardNumberInvalid"))
            .test(
              "is-valid-bin",
              t("yup.cardNumberInvalid"),
              (value) => !!cardCountry && luhnCheck(value),
            )
        : Yup.string().notRequired(),
      expiry_date: schema.creditCardForm
        ? Yup.string()
            .transform(function (_, originalValue: string) {
              return originalValue.split("/").reverse().join("");
            })
            .required(t("yup.expiryDateRequired"))
            .test("is-future-date", t("yup.expiryDateInvalid"), (value) => {
              const year = value.substring(0, 2);
              const month = value.substring(2, 4);
              const expiryDate = parse(
                `20${year}-${month}-01`,
                "yyyy-MM-dd",
                new Date(),
              );

              return isValid(expiryDate) && isFuture(expiryDate);
            })
        : Yup.string().notRequired(),
      card_security_code: schema.creditCardForm
        ? Yup.string()
            .matches(/^\d{3,4}$/, t("yup.cardNumberInvalid"))
            .required(t("yup.cvvRequired"))
        : Yup.string().notRequired(),
      collection_date: schema.collection_date
        ? Yup.date().required(t("yup.collectionDateRequired"))
        : Yup.date().notRequired(),
      isDedicated: Yup.boolean().nullable(),
      honoree_name: Yup.string().nullable(),
      honoree_email: Yup.string().email(t("yup.emailInvalid")).nullable(),
      dedication_message: Yup.string().nullable(),
    });
    const { data: user } = useGetAuthenticatedUser();
    const paymentFormRef = useRef<HTMLFormElement | null>(null);
    const {
      signatureRef,
      selectedProgram,
      inputAmount,
      donationType,
      selectedAmount,
      setCashSuccess,
      setEmail,
      quantity,
      livestock,
      deliveryAddress,
      deliveryCity,
      deliveryDate,
      isDelivery,
      deliveryPhoneNumber,
      slot,
      isSpecial,
      deliveryCountryDial,
      cardCountry,
      isDedicated,
      honoreeEmail,
      honoreeName,
      dedicationMessage,
      isChallenge,
      selectedChallenge,
      setCashDonationId,
    } = useFormStore();
    const getFullName = () => {
      if (user?.profile.firstName && user?.profile.lastName) {
        return `${user.profile.firstName} ${user.profile.lastName}`;
      }
      return "";
    };

    const formInitialValue = {
      email: user?.email ?? "",
      fullName: getFullName(),
      phoneNumber: user?.phonenumber ?? "",
      country_dial: user?.country_dial ?? null,
      address: "",
      city: "",
      card_number: "",
      expiry_date: "",
      card_security_code: "",
      collection_date: null,
      amount: selectedAmount === inputAmount ? selectedAmount : inputAmount,
      donation_type: donationType ?? null,
      program_id: selectedProgram?.id,
      quantity: quantity,
      specialDonation: isDelivery
        ? {
            deliveryAddress: deliveryAddress,
            deliveryCity: deliveryCity,
            deliveryDate: deliveryDate,
            deliveryPhoneNumber: deliveryCountryDial + deliveryPhoneNumber,
            slot: slot ? slot.id : undefined,
          }
        : undefined,
      livestock: livestock,
      isDelivery: isDelivery,
      isDedicated: isDedicated,
      honoree_name: honoreeName,
      honoree_email: honoreeEmail,
      dedication_message: dedicationMessage,
    };
    const navigate = useNavigate();
    const { mutate } = usePostDonation({
      onSuccess: (data) => {
        window.gtag(
          'event',
          'conversion',
          {
            'send_to': 'AW-1013168892/VVa4CPqwndsZEPz1juMD',
            'value': formInitialValue.amount,
            'currency': currency,
            'transaction_id': data.id
          }
        );
        setCashSuccess(true);
        setCashDonationId(data.id);
        if (isChallenge) {
          navigate({
            to: "/challenge-checkout/$challengeId",
            params: { challengeId: selectedChallenge?.id! },
          });
        } else {
          navigate({
            to: "/gratitude/$programId",
            params: { programId: selectedProgram?.id! },
          });
        }
      },
      onError: () => {
        setCashSuccess(false);
      },
    });
    const getOptionalValue = (value: any) => value || undefined;
    return (
      <Formik
        initialValues={formInitialValue}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          setEmail(values.email);
          if (selectedOption === "Credit Card") {
            localStorage.setItem(
              "user-data",
              JSON.stringify({ ...values, card_number: undefined }),
            );
            if (signatureRef.current && paymentFormRef.current) {
              signatureRef.current.value = generateSHASignature(
                new FormData(paymentFormRef.current),
              );
              paymentFormRef.current.submit();
            }
          } else if (selectedOption === "Pay in person") {
            mutate({
              data: {
                program_id: values.program_id,
                donation_type_id: values.donation_type,
                currency: currency,
                amount_egp: currency === "EGP" ? values.amount : undefined,
                amount_usd: currency === "USD" ? values.amount : undefined,
                user_email: values.email,
                user_phonenumber: values.country_dial + values.phoneNumber,
                user_fullname: values.fullName,
                payment_type: "CASH",
                collection_date:
                  values.collection_date &&
                  format(values.collection_date, "yyyy-MM-dd"),
                date: format(new Date(), "yyyy-MM-dd"),
                address: values.address,
                city: values.city,
                special_donation: isSpecial
                  ? {
                      livestock_id: values.livestock,
                      quantity: values.quantity,
                      delivery_address: values.specialDonation?.deliveryAddress,
                      delivery_city: values.specialDonation?.deliveryCity,
                      delivery_date:
                        values.specialDonation?.deliveryDate &&
                        format(
                          values.specialDonation?.deliveryDate,
                          "yyyy-MM-dd",
                        ),
                      phonenumber: values.specialDonation?.deliveryPhoneNumber,
                      delivery_slot_id: values.specialDonation?.slot,
                      isDelivery: values.isDelivery,
                    }
                  : undefined,
                isDedicated: values.isDedicated,
                honoree_name: getOptionalValue(values.honoree_name),
                honoree_email: getOptionalValue(values.honoree_email),
                dedication_message: getOptionalValue(values.dedication_message),
              },
            });
          }
        }}
        innerRef={ref}
      >
        {({ values, setFieldValue, errors, submitCount }) => (
          <InnerForm
            schema={schema}
            selectedOption={selectedOption}
            values={values}
            errors={errors}
            submitCount={submitCount}
            setFieldValue={setFieldValue}
            paymentFormRef={paymentFormRef}
          />
        )}
      </Formik>
    );
  },
);

export default CheckoutForm;
